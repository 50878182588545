<template>
  <div class="add-prikaz">
    <div class="row">
      <div class="col s3">Название</div>
      <input class="col s9" type="text" v-model="prikaz.name" placeholder="Напр., О назначении ..">
    </div>
    <div class="row">
      <i class="material-icons prefix">blur_circular</i>
      <div class="input-field col s12">
        <select v-model="prikaz.category" ref="categorySelector" @change="reloadByCategory">
          <option v-for="v in categoryValues" :value="v" :key="'opt' + v"
            :selected="(v == lastCategory) ? 'selected' : ''">{{ v
            }}
          </option>
        </select>
        <label>Категория</label>
      </div>
    </div>
    <div class="row">
      <div class="col s6">Дата приказа (если в будущем, то будет создан проект)</div>
      <div class="col s6">
        <Datetime :format="pickerformat" type="date" :phrases="{ ok: 'Сохранить', cancel: 'Отмена' }" :week-start="1"
          v-model="prikaz.signdate" :min-datetime="lastDate" zone="Asia/Krasnoyarsk" @close="datetimeClose"></Datetime>
      </div>
    </div>
    <div class="row">
      <div class="col s12" v-if="!isProject">Номер - следующий свободный ({{ prikaz.Nomer }}) - будет точно известен после
        добавления</div>
      <div class="col s12" v-else>у проекта приказа номера нет</div>

    </div>
    <div class="row buttons-row">
      <a class="col s6 waves-effect waves-orange btn btn-large light-green lighten-3 black-text" @click="doAdd"><i
          class="material-icons">add</i><span>Добавить</span></a>
      <a class="col s6 waves-effect waves-light btn btn-large red" @click="doClose"><i
          class="material-icons">cancel</i><span>Отменить</span></a>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventHub from "@/eventHub";
import { Datetime } from 'vue-datetime';
import { Settings, DateTime } from 'luxon';
//import luxon from 'luxon';
import 'vue-datetime/dist/vue-datetime.css';
import dateformat from "dateformat";

Settings.defaultLocale = 'ru';

export default {
  name: "AddPrikazForm",
  props: {
    show: { type: Boolean, default: false, },
  },
  computed: {
    ...mapGetters('Config', ['getApiRoot', 'categoryValues']),
    lastCategory() {
      return eventHub.lastCategory;
    },
  },
  data() {
    return {
      prikaz: { name: '', Nomer: 0, category: eventHub.lastCategory, content: '', approved: 0, files: null, signer: '', signdate: new Date().toISOString() },
      lastDate: new Date().toISOString(),
      pickerformat: DateTime.DATE_MED,// DATETIME_MED,
      isProject: false,
      instances: null,
    }
  },
  methods: {
    doAdd() {
      this.postPrikaz();
    },
    doClose() {
      this.$router.go(-1);
    },
    fetchNomer() {
      let self = this;
      this.$http.get(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/last_nomer', { params: { category: this.prikaz.category } }).then((res) => {
        if (res.status == 200 && res.data) {
          if ('Nomer' in res.data)
            self.prikaz.Nomer = Number(res.data.Nomer) + 1;
          if ('signdate' in res.data) {
            self.lastDate = self.formatDateTime(res.data.signdate.date);
          }
        }
      }, () => {
      });
    },
    async postPrikaz() {
      let self = this;
      if (!eventHub.isInPosting) {
        eventHub.isInPosting = true;
        setTimeout(() => { eventHub.isInPosting = false }, 5000);
        await this.$http.post(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/add', this.prikaz).then((res) => {
          if (res.status == 200 && res.data) {
            if ('message' in res.data) {
              eventHub.lastCategory = self.prikaz.category;
              eventHub.$emit('post-prikaz', res.data.message);
            }
          }
        }, () => {
        });
      }
    },
    formatDateTime(data) {
      if (data) {
        let d = new Date(data);
        d.setHours(0, 0, 0, 0);
        return d.toISOString();
        // return dateformat(date, 'yyyy-mm-dd HH-MM');
      } else return '';
    },
    datetimeClose() {
      let d = new Date();
      //d.setHours(0,0,0,0);
      d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1, 0, 0, 0, 0);
      let s = new Date(this.prikaz.signdate);
      if (s >= d) {
        this.isProject = true;
        console.log(s, d);
      } else {
        this.isProject = false;
      }
    },
    destroyCurrent() {
      if (this.instances) {
        this.instances.destroy();
      }
      if (this.tooltip_instances) {
        this.tooltip_instances.forEach((v) => {
          v.destroy();
        });
      }
    },
    reloadByCategory() {
      eventHub.lastCategory = this.prikaz.category;
      this.fetchNomer();
    },
  },
  mounted() {
    this.fetchNomer();
    setTimeout(() => {
      this.instances = window.M.FormSelect.init(this.$refs.categorySelector, {});
    }, 0);
    console.log('lastCat: ' + this.lastCategory);
  },
  beforeDestroy() {
    this.destroyCurrent();
  },
  watch: {
    show: function (newVal) { // watch it
      console.log(newVal);
      if (newVal === true) {
        this.fetchNomer();
      }
    }
  },
  components: { Datetime, },

}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
  }
}
</style>